<template>
  <div>
    <el-form
      v-loading="uploading"
      ref="form"
      :rules="rules"
      :model="form"
      label-width="150px"
    >
      <el-form-item label="比赛名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入比赛完整名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="比赛时间" prop="time">
        <el-date-picker
          v-model="form.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <el-link
          type="primary"
          underline
          @click="showFuzzyTime = true"
          class="text-btn"
          >没有确切的比赛时间？</el-link
        >
      </el-form-item>
      <el-form-item
        v-if="showFuzzyTime || this.form.fuzzyTime"
        label="模糊比赛时间"
        prop="name"
      >
        <el-input
          v-model="form.fuzzyTime"
          placeholder="请输入比赛的大致时间，若不确定请填写’待定‘"
        ></el-input>
      </el-form-item>
      <el-form-item label="比赛地点" prop="address">
        <el-input
          v-model="form.address"
          placeholder="请输入比赛地点"
        ></el-input>
      </el-form-item>
      <el-form-item label="比赛规则" prop="ruleType">
        <el-select v-model="form.ruleType" placeholder="请选择比赛规则类型">
          <el-option
            v-for="item in RULE_TYPE"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报到时间" prop="checkInTime">
        <el-input
          v-model="form.checkInTime"
          placeholder="请输入报到时间"
        ></el-input>
      </el-form-item>
      <el-form-item label="油彩喷涂时间" prop="paintTime">
        <el-input
          v-model="form.paintTime"
          placeholder="请输入油彩喷涂时间"
        ></el-input>
      </el-form-item>
      <el-form-item label="实际比赛开始时间" prop="eventStartTime">
        <el-date-picker v-model="form.eventStartTime" type="date" placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="实际比赛结束时间" prop="eventEndTime">
        <el-date-picker v-model="form.eventEndTime" type="date" placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="主办单位" prop="hosting">
        <el-input
          v-model="form.hosting"
          placeholder="请输入主办单位"
        ></el-input>
      </el-form-item>
      <el-form-item label="指导单位" prop="guiding">
        <el-input
          v-model="form.guiding"
          placeholder="请输入指导单位"
        ></el-input>
      </el-form-item>
      <el-form-item label="承办单位" prop="undertaking">
        <el-input
          v-model="form.undertaking"
          placeholder="请输入承办单位"
        ></el-input>
      </el-form-item>
      <el-form-item label="协办单位" prop="coOrganizing">
        <el-input
          v-model="form.coOrganizing"
          placeholder="请输入协办单位"
        ></el-input>
      </el-form-item>
      <el-form-item label="比赛项目" prop="events">
        <el-checkbox-group v-model="form.events">
          <el-checkbox
            v-for="item in EVENTS_CONFIG"
            :key="item.value"
            :label="item.name"
          ></el-checkbox>
        </el-checkbox-group>
        <el-button
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="addEvent"
          >添加比赛项目</el-button
        >
      </el-form-item>
      <el-form-item v-if="type === 'edit'" label="现有比赛项目">
        <el-tag
          v-for="tag in form.events"
          :key="tag"
          @close="handleClose(tag)"
          class="event-tag"
          closable
        >
          {{ tag }}
        </el-tag>
      </el-form-item>
      <el-form-item label="编辑增加的项目">
        <span>{{ addedEvents }}</span>
      </el-form-item>
      <el-form-item label="比赛组别" prop="groups">
        <el-checkbox-group v-model="form.groups">
          <el-checkbox
            v-for="item in GROUPS_CONFIG"
            :key="item.value"
            :label="item.name"
          ></el-checkbox>
        </el-checkbox-group>
        <el-button
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="addGroup"
          >添加比赛组别</el-button
        >
      </el-form-item>
      <el-form-item label="编辑增加的组别">
        <span>{{ addedGroups }}</span>
      </el-form-item>
      <el-form-item v-if="type === 'edit'" label="现有组别">
        <el-tag
          v-for="tag in form.groups"
          :key="tag"
          @close="handleGroupClose(tag)"
          class="event-tag"
          closable
        >
          {{ tag }}
        </el-tag>
      </el-form-item>
      <el-form-item label="比赛大区" prop="bigRegion">
        <el-radio-group v-model="form.bigRegion">
          <el-radio
            v-for="item in REGION_TYPE_ONE"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="比赛地理单元" prop="normalRegion">
        <el-radio-group v-model="form.normalRegion">
          <el-radio
            v-for="item in REGION_TYPE_TWO"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="赛事规模">
        <el-select v-model="form.rank" placeholder="请选择比赛规模">
          <el-option
            v-for="item in RANK_CONFIG"
            :key="item.level"
            :label="item.level"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签">
        <el-input
          v-model="form.tagStr"
          placeholder="请输入赛事标签，以逗号分隔"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否设有新秀组">
        <el-switch
          v-model="form.hasYoung"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="是否设有大学生组">
        <el-switch
          v-model="form.hasStudent"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="是否设有少年组">
        <el-switch
          v-model="form.hasChild"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="是否设有免费组别">
        <el-switch
          v-model="form.hasFreeItem"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="报名条件">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入报名限制条件"
          v-model="form.limit"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="IFBB PRO职业卡数量">
        <el-input v-model.number="form.hasCard"></el-input>
      </el-form-item>
      <el-form-item label="报名费用">
        <el-input v-model.number="form.cost" class="money-input"></el-input
        >元（人民币）
      </el-form-item>
      <el-form-item label="兼项费用">
        <el-input
          v-model.number="form.simultaneousCost"
          class="money-input"
        ></el-input
        >元（人民币）
      </el-form-item>
      <el-form-item label="费用说明">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入备注内容"
          v-model="form.costExplanation"
        />
      </el-form-item>
      <el-form-item label="是否允许自备油彩">
        <el-switch
          v-model="form.paintFree"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
        <span class="left-margin-text">当前值为：{{ form.paintFree }}</span>
        <el-button
          class="left-margin-btn"
          size="small"
          @click="clearPaintFree"
          >重置为默认</el-button
        >
      </el-form-item>
      <el-form-item label="官方油彩费用">
        <el-input
          v-model="form.paintPrice"
          type="textarea"
          autosize
        ></el-input
        >
      </el-form-item>
      <el-form-item label="奖励设置">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入奖励设置"
          v-model="form.awards"
        />
      </el-form-item>
      <el-form-item label="奖金总额">
        <el-input
          autosize
          placeholder="请输入奖励设置"
          v-model.number="form.awardsTotal"
        />
      </el-form-item>
      <el-form-item label="报名方式">
        <template>
          <div class="register-item">
            <span class="label">报名方式类型</span>
            <el-select
              v-model="form.register.type"
              placeholder="请选择报名方式类型"
            >
              <el-option
                v-for="item in REGISTER_TYPE"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="register-item">
            <span class="label">报名截止日期</span>
            <el-date-picker
              v-model="form.register.deadline"
              type="date"
              placeholder="请选择报名截止日期"
            >
            </el-date-picker>
          </div>
          <div class="register-item">
            <span class="label"
              >报名方式（报名链接/报名二维码链接/微信号/手机号/邮箱等）</span
            >
            <el-upload
              v-if="formObj.register.type === 1"
              action=""
              :http-request="onUploadQRcode"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :limit="1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-input
              v-model="form.register.url"
              placeholder="请根据选择的报名类型填写报名信息"
            ></el-input>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="联系人" class="contact-wrapper">
        <template>
          <div
            v-for="(item, index) in form.contact"
            :key="index"
            class="contact-item"
          >
            <span class="label">联系人姓名</span>
            <el-input v-model="item.name" style="width: 150px"></el-input>
            <span class="label">联系方式类型</span>
            <el-select v-model="item.type" placeholder="请选择报名方式类型">
              <el-option
                v-for="item in CONTACT_TYPE"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="label">联系方式</span>
            <el-input v-model="item.contact" style="width: 150px"></el-input>
          </div>
          <el-button
            icon="el-icon-circle-plus-outline"
            size="small"
            @click="addContact"
            >添加联系人</el-button
          >
          <el-button
            size="small"
            @click="clearContact"
            >清空联系人</el-button
          >
        </template>
      </el-form-item>
      <el-form-item label="比赛规程链接">
        <el-input
          v-model="form.regulationUrl"
          placeholder="请输入竞赛规程链接"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入备注内容"
          v-model="form.remarks"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="是否延期">
        <el-switch
          v-model="form.delayTag"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="海报链接">
        <el-upload
          class="image-upload"
          action=""
          :http-request="onUploadPoster"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :limit="1"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="imgPreviewDialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <el-input v-model="form.poster" placeholder="请输入海报链接"></el-input>
      </el-form-item>
      <el-form-item label="是否开启报名">
        <el-switch
          v-model="form.enableRegister"
          active-color="#13ce66"
          inactive-color="#ff4949"
          disabled
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="是否可达级">
        <el-switch
          v-model="form.reachingLevel"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="运动员群二维码">
        <el-upload
          class="image-upload"
          action=""
          :http-request="onUploadGroupQrcode"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :limit="1"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-input v-model="form.groupQrcode" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="是否已删除">
        <el-switch
          v-model="form.isDelete"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="规程PDF链接">
        <el-input v-model="form.pdfUrl" placeholder="请输入规程PDF链接"></el-input>
      </el-form-item>
      <el-form-item label="最新赛事通知">
        <el-input v-model="form.notice" placeholder="请输入赛事最新通知" type="textarea" autosize></el-input>
      </el-form-item>
      <el-form-item label="是否自然赛">
        <el-switch
          v-model="form.isNuture"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="药检方式">
        <el-input v-model="form.detection" placeholder="请输入药检方式说明"></el-input>
      </el-form-item>
      <el-form-item label="是否显示WADA标志">
        <el-switch
          v-model="form.showWada"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="是否显示门票信息">
        <el-switch
          v-model="form.enableTicket"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <div v-if="form.enableTicket" class="ticket-block">
        <el-form-item label="门票价格">
          <el-input v-model="form.ticketPrice" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="比赛城市">
          <el-input v-model="form.ticketCity" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="地址纬度">
          <el-input v-model="form.latitude" placeholder="请输入"></el-input>
          <el-link
            href="https://lbs.qq.com/getPoint/#Z"
            target="_blank"
            type="primary"
            >获取位置信息</el-link
          >
        </el-form-item>
        <el-form-item label="地址经度">
          <el-input v-model="form.longitude" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="观赛详细地址">
          <el-input
            v-model="form.addressDetail"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="门票详情">
          <el-input
            type="textarea"
            autosize
            placeholder="请输入"
            v-model="form.ticketDesc"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="比赛详细时间">
          <el-input v-model="form.detailTime" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="观众入场时间">
          <el-input v-model="form.entranceTime" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="观众注意事项">
          <el-input
            type="textarea"
            autosize
            placeholder="请输入"
            v-model="form.attention"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="购票链接">
          <el-input v-model="form.ticketUrl" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="购票二维码">
          <el-upload
            class="image-upload"
            action=""
            :http-request="onUploadTicketQrcode"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :limit="1"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-input v-model="form.ticketQrcode" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="购票电话">
          <el-input v-model="form.ticketPhone" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="购票微信">
          <el-input v-model="form.ticketWechat" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="门票海报">
          <el-upload
            class="image-upload"
            action=""
            :http-request="onUploadTicketPoster"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :limit="1"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-input v-model="form.tickerPoster" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="是否免费预约">
          <el-switch
            v-model="form.isFreeAppointment"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
      </div>
      <el-form-item class="footer-btn">
        <el-button type="primary" @click="onSubmit">{{
          type === "edit" ? "保存修改" : "立即创建"
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  EVENTS_CONFIG,
  GROUPS_CONFIG,
  RANK_CONFIG,
  REGISTER_TYPE,
  CONTACT_TYPE,
  REGION_TYPE_ONE,
  REGION_TYPE_TWO,
  RULE_TYPE
} from "./constant";
import uploadMixin from "../../mixin/upload";

export default {
  props: {
    formObj: {
      type: Object,
      default: () => ({
        name: "",
        time: "", // TODO:从数组转化为startTime和endTime
        fuzzyTime: "",
        checkInTime: "",
        paintTime: "",
        eventStartTime: "",
        eventEndTime: "",
        address: "",
        hosting: "",
        guiding: "",
        undertaking: "",
        coOrganizing: "",
        events: [],
        groups: [],
        rank: "",
        tags: [], // TODO:转化为数组
        tagStr: "",
        hasYoung: false,
        hasStudent: false,
        hasChild: false,
        hasFreeItem: false,
        ruleType: 1,
        limit: "",
        hasCard: 0,
        cost: 0,
        costExplanation: "",
        simultaneousCost: 0,
        isRecommend: false,
        isDelete: false,
        register: {
          type: 0,
          deadline: "",
          url: "",
        },
        isTop: false,
        priority: 0,
        contact: [],
        remarks: "",
        regulationUrl: "",
        awards: "",
        awardsTotal: 0,
        delayTag: false,
        poster: "",
        enableRegister: false,
        bigRegion: -1,
        normalRegion: -1,
        reachingLevel: false, // 是否可达级
        groupQrcode: "",
        enableTicket: false,
        ticketPrice: "",
        addressDetail: "",
        ticketDesc: "",
        entranceTime: "",
        attention: "",
        ticketUrl: "",
        ticketQrcode: "",
        ticketPhone: "",
        ticketWechat: "",
        tickerPoster: "",
        isFreeAppointment: false,
        detailTime: "",
        ticketCity: "",
        latitude: "",
        longitude: "",
        paintFree: "",
        paintPrice: "",
        pdfUrl: "",
        notice: "",
        isNuture: false,
        detection: "",
        showWada: false
      }),
    },
    type: {
      type: String,
      default: "add",
    },
  },
  mixins: [uploadMixin],
  watch: {
    tempImgUrl(newVal) {
      if (this.currentUpload === "register") {
        this.formObj.register.url = newVal;
      } else {
        this.formObj[this.currentUpload] = newVal;
      }
    },
  },
  mounted() {
    // 绑定进度监听函数
    this.$ttUploader.on("complete", (data) => {
      if (data.type === "success") {
        this.tempImgUrl =
          "https://cdn.jirou.fit/" +
          data.uploadResult.ImageUri +
          "~tplv-700tmnc25s-yasuo.image";
        this.uploading = false;
        this.$message({
          type: "success",
          message: "图片上传成功",
        });
      }
    });
    // 绑定错误监听函数
    this.$ttUploader.on("error", (infor) => {
      this.uploading = false;
      this.$message({
        type: "error",
        message: infor.extra,
      });
    });
  },
  data() {
    return {
      EVENTS_CONFIG,
      GROUPS_CONFIG,
      RANK_CONFIG,
      REGISTER_TYPE,
      CONTACT_TYPE,
      REGION_TYPE_ONE,
      REGION_TYPE_TWO,
      RULE_TYPE,
      showFuzzyTime: false,
      addedEvents: [],
      addedGroups: [],
      rules: {
        name: [
          { required: true, message: "请输入比赛名称", trigger: "blur" },
          {
            min: 3,
            max: 200,
            message: "长度在 3 到 200 个字符",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请输入比赛地点", trigger: "blur" },
          {
            min: 3,
            max: 200,
            message: "长度在 3 到 200 个字符",
            trigger: "blur",
          },
        ],
        events: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个比赛项目",
            trigger: "change",
          },
        ],
        groups: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个比赛组别",
            trigger: "change",
          },
        ],
      },
      tempImgUrl: "",
      uploading: false,
      currentUpload: "", // 当前正在上传的字段
    };
  },
  computed: {
    form() {
      return this.formObj;
    },
  },
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return this.$message.error("表单信息填写错误，请根据提示检查！");
        }
        if (!this.checkContact()) return;
        if (this.form.time.length === 2) {
          this.form.startTime = this.form.time[0];
          this.form.endTime = this.form.time[1];
        }
        this.form.tags = this.form.tagStr ? this.form.tagStr.split(",") : [];
        this.form.events = this.form.events.concat(this.addedEvents);
        this.form.groups = this.form.groups.concat(this.addedGroups);
        if (this.form.paintFree === '') {
          delete this.form.paintFree
        }
        if (this.form.paintPrice === '') {
          delete this.form.paintPrice
        }

        let url = "";
        if (this.type === "edit") {
          url = "/update";
        } else {
          url = "/add_event";
        }
        this.$axios
          .post(url, JSON.stringify(this.form), {
            headers: {
              "x-auth-token": sessionStorage.getItem("bde_token"),
            },
          })
          .then((res) => {
            if (res.data.code === "success") {
              this.$message.success("操作成功！");
              this.$emit("edit-success");
              this.addedEvents = [];
              this.addedGroups = [];
              this.$router.push("/");
            } else {
              this.$message.error("请求错误！");
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.response.data.message);
            if (err.response.data.code === "token_timeout") {
              this.$message.error("登录过期，请重新登录！");
            }
          });
      });
    },
    addContact() {
      this.form.contact.push({
        name: "",
        type: 0,
        contact: "",
      });
    },
    checkContact() {
      let flag = true;
      this.form.contact.map((item) => {
        if (!item.name || !item.contact) {
          this.$message.error("联系人信息未填写完整！");
          return (flag = false);
        }
      });
      return flag;
    },
    addEvent() {
      this.$prompt("请输入比赛项目名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.{2,50}$/,
        inputErrorMessage: "项目名称格式不正确",
      })
        .then(({ value }) => {
          this.addedEvents.push(value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    addGroup() {
      this.$prompt("请输入组别名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.{2,50}$/,
        inputErrorMessage: "组别名称不正确",
      })
        .then(({ value }) => {
          this.addedGroups.push(value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    handleClose(tag) {
      this.form.events.splice(this.form.events.indexOf(tag), 1);
    },
    handleGroupClose(tag) {
      this.form.groups.splice(this.form.groups.indexOf(tag), 1);
    },
    onUploadPoster(param) {
      this.currentUpload = "poster";
      this.uploading = true;
      // 上传图片到火山引擎
      this.uploadImg(param);
    },
    onUploadQRcode(param) {
      this.currentUpload = "register";
      this.uploading = true;
      // 上传图片到火山引擎
      this.uploadImg(param);
    },
    onUploadGroupQrcode(param) {
      this.currentUpload = "groupQrcode";
      this.uploading = true;
      // 上传图片到火山引擎
      this.uploadImg(param);
    },
    onUploadTicketQrcode(param) {
      this.currentUpload = "ticketQrcode";
      this.uploading = true;
      // 上传图片到火山引擎
      this.uploadImg(param);
    },
    onUploadTicketPoster(param) {
      this.currentUpload = "tickerPoster";
      this.uploading = true;
      // 上传图片到火山引擎
      this.uploadImg(param);
    },
    clearContact() {
      this.form.contact = [];
    },
    clearPaintFree() {
      this.form.paintFree = null;
    }
  },
};
</script>

<style scoped>
.money-input {
  width: 100px;
  margin-right: 10px;
}

.register-item {
  min-height: 50px;
}

.register-item > .label {
  display: inline-block;
  margin-right: 10px;
}

.contact-wrapper {
  margin-top: 50px;
}

.contact-item {
  margin-bottom: 20px;
}

.contact-item > .label {
  margin: 0 10px 0 20px;
}

.contact-item > span:first-child {
  margin-left: 0;
}

.footer-btn {
  margin-top: 100px;
}

.text-btn {
  display: inline-block;
  margin-left: 20px;
}

.event-tag {
  margin: 5px;
}

.image-upload {
  margin-bottom: 10px;
}
.ticket-block {
  padding: 20px;
  border-radius: 20px;
  border: 1px dashed #999;
}
.left-margin-btn, .left-margin-text {
  margin-left: 10px;
}
</style>
